import { AbsoluteTimeRange } from '@grafana/data'

import {
  RUN_PROCESS,
  K6TestRunStatusesActive,
  TestRun,
  TestRunProcessingStatus,
  TestRunResultStatus,
  TestRunResultStatusText,
  TestRunStatus,
  TestRunStatusText,
  TestRunDeleteStatus,
  TestRunBase,
} from 'types'
import { ExportDataStatus } from 'constants/tests'

import { inArray } from './array'
import { dateFormatter, resolveTimeZone, toTimestamp } from './date'
import { to2DecimalPoints } from './math'
import { Falsy } from './typescript'

export const canShowResultStatus = (testRun: TestRunBase) => {
  return (
    isTestDone(testRun) &&
    testRun.run_status !== TestRunStatus.ABORTED_USER &&
    testRun.run_status !== TestRunStatus.TIMED_OUT &&
    testRun.result_status === TestRunResultStatus.FAILED
  )
}

export const getTestRunResultText = (testRun: TestRunBase) => {
  if (canShowResultStatus(testRun)) {
    return getResultStatusText(testRun.result_status)
  }

  return getRunStatusText(testRun.run_status)
}

export const getResultStatusText = (status: TestRunResultStatus) => {
  return TestRunResultStatusText[status] || ''
}

export const getRunStatusText = (status: TestRunStatus) => {
  return TestRunStatusText[status] || ''
}

export function isSecureRun(testRun: TestRun) {
  return testRun.run_process === RUN_PROCESS.K6_TO_CLOUD_SECURE
}

export const isTestActive = (testRun: TestRunBase) => {
  return (
    testRun &&
    (K6TestRunStatusesActive.includes(testRun.run_status) ||
      isTestProcessing(testRun))
  )
}

export const isTestDone = (testRun: TestRunBase) => !isTestActive(testRun)

export const isTestExportActive = inArray([
  ExportDataStatus.Requested,
  ExportDataStatus.Running,
])

export const isTestExportFinished = (testRun: TestRun) => {
  return testRun.export?.export_status === ExportDataStatus.Finished
}

export const isTestRunComparable = (testRun: TestRun) => {
  return (
    !isTestTimedOut(testRun) &&
    !isTestActive(testRun) &&
    !isTestRunExpired(testRun) &&
    !isTestAbortedByScriptError(testRun) &&
    !isTestRunUploaded(testRun)
  )
}

export const isTestAborted = (testRun: TestRun) =>
  [
    TestRunStatus.ABORTED_USER,
    TestRunStatus.ABORTED_SYSTEM,
    TestRunStatus.ABORTED_SCRIPT_ERROR,
    TestRunStatus.ABORTED_THRESHOLD,
    TestRunStatus.ABORTED_LIMIT,
  ].includes(testRun.run_status)

export const isTestRunExpired = (testRun: TestRunBase) =>
  testRun.delete_status === TestRunDeleteStatus.DELETED_EXPIRE

export const isTestAbortedByScriptError = (testRun: TestRun) => {
  return testRun.run_status === TestRunStatus.ABORTED_SCRIPT_ERROR
}

export const isTestProcessing = (testRun: TestRunBase) => {
  return testRun.processing_status === TestRunProcessingStatus.PROCESSING
}

export const isTestRunUploaded = (testRun: TestRun) =>
  testRun.run_status === TestRunStatus.UPLOADED

export const isTestRunning = (testRun: TestRun) =>
  testRun.run_status === TestRunStatus.RUNNING

export const isTestFailed = (testRun: TestRun) =>
  testRun.result_status === TestRunResultStatus.FAILED

export const isTestTimedOut = (testRun: TestRun) =>
  testRun.run_status === TestRunStatus.TIMED_OUT

export const isTestRunFromIngest = (testRun: TestRun) =>
  testRun.run_process === RUN_PROCESS.K6_INGEST

export const isTestPreparingToStart = (testRun: TestRun) => {
  return (
    K6TestRunStatusesActive.includes(testRun.run_status) &&
    !isTestRunning(testRun) &&
    !isTestRunFromIngest(testRun)
  )
}

export const hasSystemError = (testRun: TestRun) => {
  return (
    testRun.run_status === TestRunStatus.ABORTED_SYSTEM ||
    testRun.run_status === TestRunStatus.TIMED_OUT ||
    testRun.run_status === TestRunStatus.ABORTED_LIMIT
  )
}

export const hasScriptError = (testRun: TestRun) => {
  return testRun.run_status === TestRunStatus.ABORTED_SCRIPT_ERROR
}

export const isTestSafe = (testRun: TestRunBase) =>
  // cspell:disable-next-line
  testRun.delete_status === TestRunDeleteStatus.NOEXPIRE

export const isTestFailedWithoutData = (testRun: TestRun) =>
  testRun.started === null || testRun.ended === null

export const isLocalExecution = (testRun: TestRun) =>
  [RUN_PROCESS.K6_INGEST, RUN_PROCESS.K6_INGEST_WITH_THRESHOLDS].includes(
    testRun.run_process
  )

export const getTestRunLabel = (
  testRun: Falsy<TestRun>,
  timeZone: string = resolveTimeZone()
) => {
  if (!testRun) {
    return '-'
  }

  if (testRun.started) {
    if (testRun.note) {
      return testRun.note
    }

    return dateFormatter.tz(testRun.started, 'MMM dd HH:mm', timeZone)
  }

  if (isTestActive(testRun)) {
    return getRunStatusText(testRun.run_status)
  }

  return getTestRunResultText(testRun)
}

export const isTestRunInWorkingState = (testRun: TestRun) => {
  return isTestPreparingToStart(testRun) || isTestRunning(testRun)
}

export function canExportSummary(testRun: TestRun) {
  return (
    !isTestActive(testRun) &&
    !isTestRunExpired(testRun) &&
    !isTestRunUploaded(testRun) &&
    !hasSystemError(testRun) &&
    !hasScriptError(testRun)
  )
}

export const getTotalVuhCost = (testRun?: TestRun) => {
  const { vuh_cost = 0, vuh_browser_cost = 0 } = testRun || {}

  return to2DecimalPoints(vuh_browser_cost + vuh_cost)
}

export const getTotalVus = (testRun?: TestRun) => {
  const { vus = 0 } = testRun || {}

  // vus is the sum of "protocol_vus" + browser_vus
  return vus
}

export function getTestRunTime(
  testRun: TestRun,
  timeRange?: AbsoluteTimeRange
) {
  const start = Math.round(timeRange?.from || toTimestamp(testRun.created))
  const end = Math.round(
    timeRange?.to || (testRun.ended ? toTimestamp(testRun.ended) : Date.now())
  )

  return {
    start,
    end,
  }
}

export function getRightTestRunTime(
  left: TestRun,
  right: TestRun | undefined,
  timeRange?: AbsoluteTimeRange
) {
  if (!right) {
    return {
      start: undefined,
      end: undefined,
    }
  }

  const timeRangeFrom = timeRange?.from && Math.round(timeRange?.from)
  const timeRangeTo = timeRange?.to && Math.round(timeRange?.to)
  const timeRangeDuration =
    timeRangeFrom && timeRangeTo ? timeRangeTo - timeRangeFrom : 0

  const leftCreated = toTimestamp(left.created)
  const rightCreated = toTimestamp(right.created)
  const rightEnded = toTimestamp(right.ended!)

  // Calculate the difference between the left created time and the time range from.
  // The time range is based on the left test run so it must be calculated this way.
  const leftCreatedDifference = timeRangeFrom ? timeRangeFrom - leftCreated : 0
  // Take the right created time and adjust it by the difference calculated above.
  const rightCreatedAdjusted = rightCreated + leftCreatedDifference
  // Now push the adjusted right created time by the duration of the time range.
  const rightEndedAdjusted = timeRangeDuration
    ? rightCreatedAdjusted + timeRangeDuration
    : rightEnded

  return {
    start: rightCreatedAdjusted,
    end: rightEndedAdjusted,
  }
}
